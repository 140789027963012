import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import FeatureBlock from 'common/components/FeatureBlock';
import { SectionHeader } from '../app-minimal.style';
import SectionWrapper, { TextWrapper } from './nettoyage.style';

const Nettoyage = () => {
  const Data = useStaticQuery(graphql`
    query {
      appMinimalJson {
        nettoyage {
          title
          description
          features {
            id
            description
          }
          title2
          description2
          features2 {
            id
            description
          }
          title3
          description3
          features3 {
            id
            description
          }
        }
      }
    }
  `);
  const { title, title2, title3, description, description2, description3, features, features2, features3 } =
    Data.appMinimalJson.nettoyage;
  return (
    <SectionWrapper>
      <Container>

        <TextWrapper>
          <SectionHeader className="section-header-two">
            <Heading content={title} />
            <Text content={description} />
          </SectionHeader>

          {features.map((item) => (
            <FeatureBlock
              key={`app-feature--key${item.id}`}
              description={<Text content={item.description} />}
            />
          ))}
        </TextWrapper>
      </Container>

      <Container>
        <TextWrapper>
          <SectionHeader className="section-header-two">
            <Heading content={title3} />
            <Text content={description3} />
          </SectionHeader>

          {features3.map((item) => (
            <FeatureBlock
              key={`app-feature--key${item.id}`}
              description={<Text content={item.description} />}
            />
          ))}
        </TextWrapper>
      </Container>
      
      <Container>
        <TextWrapper>
          <SectionHeader className="section-header-two">
            <Heading content={title2} />
            <Text content={description2} />
          </SectionHeader>

          {features2.map((item) => (
            <FeatureBlock
              key={`app-feature--key${item.id}`}
              description={<Text content={item.description} />}
            />
          ))}
        </TextWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Nettoyage;
